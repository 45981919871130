<template>
  <main class="carte-main">
    <Header></Header>
    
    <transition name="traXE" appear>
    <div class="container-slogan-carte">
      <h1 class="titre-carte">Notre carte</h1>
    </div>
    </transition>
    
    <h4 class="sub-titre-carte">Frites</h4>
    
    <transition name="traXE" appear>
    <div class="frites-container">
      <div class="frite">
        <div class="sub-frite">
          <img src="../assets/img/frites.png" alt="petite frite" class="frite-icon-s">
        </div>
        <div class="sub-frite">
          <p class="list-i-f">Petite</p>
        </div>
        <div class="sub-frite">
          <p class="price-art">2.00€</p>
        </div>
      </div>

            <div class="frite">
        <div class="sub-frite">
          <img src="../assets/img/frites.png" alt="petite frite" class="frite-icon-m">
        </div>
        <div class="sub-frite">
          <p class="list-i-f">Moyenne</p>
        </div>
        <div class="sub-frite">
          <p class="price-art">3.00€</p>
        </div>
      </div>

            <div class="frite">
        <div class="sub-frite">
          <img src="../assets/img/frites.png" alt="petite frite" class="frite-icon-t">
        </div>
        <div class="sub-frite">
          <p class="list-i-f">Grande</p>
        </div>
        <div class="sub-frite">
          <p class="price-art">4.00€</p>
        </div>
      </div>

    </div>
    </transition>


    <h4 class="sub-titre-carte">Nos formules</h4>

    <div class="formules-container">
      <div class="formule">
        <h4 class="titre-formule">Formule burger</h4>
        <p class="list-i-f">Burger maison</p>
        <p class="list-i-f">+ Petite frite</p>
        <p class="list-i-f">+ Boisson</p>
        <p class="price-f">6.50€</p>
      </div>

      <div class="formule">
        <h4 class="titre-formule">Formule assiettes</h4>
        <p class="list-i-f">Assiette de salade</p>
        <p class="list-i-f">+ viande au choix</p>
        <p class="list-i-f">+ Petite frite</p>
        <p class="list-i-f">+ Boisson</p>
        <p class="price-f">8.00€</p>
      </div>

      <div class="formule">
        <h4 class="titre-formule">Menu enfant</h4>
        <p class="list-i-f">
          Chixfingers, Nuggets, Fricadelle ou Cheese burger
        </p>
        <p class="list-i-f">+ Frites</p>
        <p class="list-i-f">+ Jus de pomme</p>
        <p class="list-i-f">+ Compote</p>
        <p class="list-i-f">+ Bonbons</p>
        <p class="price-f">8.00€</p>
      </div>
    </div>

    
    <div class="container-b-p-p">
      <div class="container-flex-bpp">
    <h4 class="sub-titre-carte">Burgers</h4>

    <div class="burgers" data-aos="fade-right">
      <div class="one-article">
        <p class="burger">Burger cheese<span class="price-art">2.50€</span></p>
        <p class="descri-art">Steak burger, cheddar,sauce au choix</p>
      </div>
      <div class="one-article">
        <p class="burger">Burger maison<span class="price-art">2.50€</span></p>
        <p class="descri-art">Steak burger, crudités, cheddar, sauce au choix</p>
      </div>
      <div class="one-article">
        <p class="burger">Burger raclette<span class="price-art">2.50€</span></p>
        <p class="descri-art">Steak burger, crudités, raclette, sauce au choix</p>
      </div>
      <div class="one-article">
        <p class="burger">Burger chicken<span class="price-art">2.50€</span></p>
        <p class="descri-art">Poulet pané, salade, mayonnaise</p>
      </div>
      <div class="one-article">
        <p class="burger">Burger fish<span class="price-art">2.50€</span></p>
        <p class="descri-art">Poisson pané, salade, tomates, sauce tartare</p>
      </div>
      <div class="one-article">
        <p class="burger">Bicky<span class="price-art">2.50€</span></p>
        <p class="descri-art">Steak burger, cornichons, oignons séchés, sauce bicky</p>
      </div>
      <div class="one-article">
        <p class="burger">Croque Monsieur<span class="price-art">2.50€</span></p>
        <p class="descri-art">Jambon, gruyère, toastinette</p>
      </div>
    </div>
    </div>
    
    <div class="container-ppa">
    <div class="container-flex-bpp mid">
    <h4 class="sub-titre-carte">Paninis</h4>

    <div class="burgers" data-aos="fade-down">
      <div class="one-article">
        <p class="burger">Traditionnel<span class="price-art">5.50€</span></p>
        <p class="descri-art">Jambon, mozzarela, tomates, emmental</p>
      </div>

      <div class="one-article">
        <p class="burger">Roquefort<span class="price-art">5.50€</span></p>
        <p class="descri-art">Steak haché, roquefort, oignons</p>
      </div>

      <div class="one-article">
        <p class="burger">Chorizo<span class="price-art">5.50€</span></p>
        <p class="descri-art">Chorizo, tomates, oignons, emmental, andalouse</p>
      </div>

        <div class="one-article">
        <p class="burger">Raclette<span class="price-art">5.50€</span></p>
        <p class="descri-art">Steak haché, gruyère, fromage à raclette</p>
      </div>

    </div>
    </div>
    
    <div class="container-flex-bpp mid">
    <h4 class="sub-titre-carte">Printaniers</h4>

    <div class="burgers" data-aos="fade-up">

      <div class="one-article">
        <p class="burger">Thon<span class="price-art">6.00€</span></p>
        <p class="descri-art">Steak burger, cheddar, sauce au choix</p>
      </div>

      <div class="one-article">
        <p class="burger">Jambon<span class="price-art">6.00€</span></p>
        <p class="descri-art">Jambon, salade, tomates, oeufs, mayonnaise</p>
      </div>

      <div class="one-article">
        <p class="burger">Printanier<span class="price-art">7.00€</span></p>
        <p class="descri-art">Thon ou jambon avec frites</p>
      </div>
      
    </div>
    </div>
    </div>

    <div class="container-flex-bpp">
    <h4 class="sub-titre-carte">Viande seule</h4>

    <div class="viande-container">
      
    <p class="burger viande-carte" data-aos="zoom-in">Maxicanto<span class="price-art">2.50€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Fricadelle<span class="price-art">2.50€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Viandelle<span class="price-art">2.50€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Cervelas<span class="price-art">3.50€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Merguez<span class="price-art">2.50€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Saucisse de francfort<span class="price-art">2.50€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Saucisse blanche<span class="price-art">2.50€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Brochette oignons<span class="price-art">3.00€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Brochette provençale<span class="price-art">3.00€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Brochette ardennaise<span class="price-art">3.50€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Brochette grizzly<span class="price-art">2.50€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Poulyroc<span class="price-art">2.50€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Nuggets 6 pièces<span class="price-art">3.00€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Chixfingers 6 pièces<span class="price-art">3.00€</span></p>
    <p class="burger viande-carte" data-aos="zoom-in">Croquettes de fromage<span class="price-art">3.00€</span></p>

    </div>
    </div>
    </div>
        
        <div class="container-ase">
              <div class="container-flex-bpp salade">
    <h4 class="sub-titre-carte">Asiettes de salade</h4>

        <div class="burgers" data-aos="zoom-in">

      <div class="one-article">
        <p class="burger">Asiette de salade<span class="price-art">6.00€</span></p>
        <p class="descri-art">Salade, tomates, asperges, oeufs, maïs, viande au choix</p>
      </div>

      <div class="one-article">
        <p class="burger">Asiette + frites<span class="price-art">7.00€</span></p>
        <p class="descri-art">Asiette de salade avec des frites</p>
      </div>
      
    </div>
    </div>

        <div class="container-flex-bpp">
        <h4 class="sub-titre-carte">Alcool / Soft</h4>

       <div class="burgers" data-aos="zoom-in-up">

      <div class="one-article">
        <p class="burger">Verre de vin Rosé<span class="price-art">2.50€</span></p>
      </div>

      <div class="one-article">
        <p class="burger">Bouteille de Rosé<span class="price-art">2.50€</span></p>
      </div>

            <div class="one-article">
        <p class="burger">Kronebourg<span class="price-art">2.50€</span></p>
      </div>

            <div class="one-article">
        <p class="burger">Leffe blonde 33cl<span class="price-art">2.50€</span></p>
      </div>

            <div class="one-article">
        <p class="burger">Bouteille d'eau 50cl<span class="price-art">2.50€</span></p>
      </div>

      <div class="one-article">
        <p class="burger">Canette de soda<span class="price-art">2.50€</span></p>
      </div>
      
    </div>
    </div>


        <div class="container-flex-bpp">
    <h4 class="sub-titre-carte">Extras</h4>

      <div class="burgers">

      <div class="one-article" data-aos="zoom-in">
        <p class="burger">Pot de sauce<span class="price-art">1.50€</span></p>
        <p class="descri-art">Sauce au choix</p>
      </div>

      <div class="one-article" data-aos="zoom-in">
        <p class="burger">Sauce chaude fromagère<span class="price-art">3.00€</span></p>
      </div>

      <div class="one-article" data-aos="zoom-in">
        <p class="burger">Supplément crudités<span class="price-art">1.50€</span></p>
        <p class="descri-art">Salade, tomates, oignons, cornichons</p>
      </div>

      <div class="one-article" data-aos="zoom-in">
        <p class="burger">Supplément viande<span class="price-art">2.50€</span></p>
        <p class="descri-art">Chorizo, lardons, jambon</p>
      </div>

      <div class="one-article" data-aos="zoom-in">
        <p class="burger">Supplément fromage<span class="price-art">1.50€</span></p>
        <p class="descri-art">Cheddar, raclette, roquefort, gruyère</p>
      </div>

    </div>
    </div>
    </div>

    <Footer></Footer>

    
  </main>
</template>

<script>
import AOS from "aos";
import Header from "../components/Header.vue";
import Footer from '../components/Footer.vue'

export default {
  components: {
    Header,
    Footer,
  },

  created() {
      AOS.init(); 
  },
};
</script>

<style>
</style>