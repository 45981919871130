<template>
<PageTransition></PageTransition>
        
         <transition name="menu">
         <div class="menu-mobile" v-if="this.$store.state.appearMenu">
             <h2 class="menu-title">MENU</h2>
             <div class="router-menu-container">
             <router-link to="/accueil" class="router-menu">Accueil</router-link>
             </div>
             <div class="router-menu-container">
             <router-link to="/carte" class="router-menu">Carte</router-link>
             </div>
              <div class="router-menu-container">
             <router-link to="/specialites" class="router-menu">Spécialités</router-link>
             </div>
            <div class="router-menu-container">
             <router-link to="/horaires" class="router-menu">Horaires</router-link>
             </div>
               <div class="router-menu-container">
             <router-link to="/localisation" class="router-menu">Où nous trouver ?</router-link>
             </div>
         </div>
         </transition>

         <transition name="fadeE" appear>
         <div class="header">
           <div class="header-left small">
               <img src="../assets/img/menu.png" alt="icône de menu" class="icons" @click="appearMenu()" v-if="!this.$store.state.appearMenu">
               <img src="../assets/img/cross.png" alt="icône de menu" class="icons" @click="disappearMenu()" v-if="this.$store.state.appearMenu">
           </div>
           <div class="header-mid large">
               <!-- <h1 class="name-site">FDLF</h1> -->
               <img src="../assets/img/logo.jpeg" alt="logo" class="logo-fdlf">
           </div>
           <div class="header-right small">
               <p class="adresse-header">Pl. de la Fontaine, 08110 Carignan</p>
           </div>
       </div>
     </transition>
</template>

<script>
import PageTransition from "./PageTransition.vue";

export default {
    components : {
        PageTransition,
    },

   methods : {
       appearMenu() {
           this.$store.state.appearMenu = true;
           const body = document.querySelector('.carte-main');
           body.style.height = "100vh";
           body.style.overflow = "hidden";
       },

        disappearMenu() {
           this.$store.state.appearMenu = false;
           const body = document.querySelector('.carte-main');
           body.style.height = "auto";
           body.style.overflow = "scroll";
       },
   },

         mounted() {
        this.$store.state.appearMenu = false;
  },
}
</script>

<style>

</style>