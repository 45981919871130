<template>
<main class="carte-main">
  <Header></Header>
      <h1 class="titre-carte">Nos horaires d'été</h1>
  <div class="horaires-container">
    <div class="paire-h">

     <div class="horraires">
         <p class="jour">Lundi</p>
         <p class="heures">11:30-13:30 / 18:00-21:30</p>
     </div>

          <div class="horraires">
         <p class="jour">Mardi</p>
         <p class="heures fermé">Fermé / Fermé</p>
     </div>
     
     </div>

    <div class="paire-h">
          <div class="horraires">
         <p class="jour">Mercredi</p>
         <p class="heures">11:30-13:30 / 18:00-21:30</p>
     </div>

          <div class="horraires">
         <p class="jour">Jeudi</p>
         <p class="heures">11:30-13:30 / 18:00-21:30</p>
     </div>

    </div>

        <div class="paire-h">
          <div class="horraires">
         <p class="jour">Vendredi</p>
         <p class="heures">11:30-13:30 / 18:00-21:30</p>
     </div>
          <div class="horraires">
         <p class="jour">Samedi</p>
         <p class="heures">11:30-13:30 / 18:00-21:30</p>
     </div>
     </div>
     
          <div class="horraires">
         <p class="jour">Dimanche</p>
         <p class="heures"><span class="fermé">Fermé</span> / 18:00-21:30</p>
     </div>
  </div>

        <h1 class="titre-carte hiver">Nos horaires d'hiver</h1>
  <div class="horaires-container">
    <div class="paire-h">

     <div class="horraires">
         <p class="jour">Lundi</p>
         <p class="heures">11:30-13:30 / 18:00-21:00</p>
     </div>

          <div class="horraires">
         <p class="jour">Mardi</p>
         <p class="heures fermé">Fermé / Fermé</p>
     </div>
     
     </div>

    <div class="paire-h">
          <div class="horraires">
         <p class="jour">Mercredi</p>
         <p class="heures">11:30-13:30 / 18:00-21:00</p>
     </div>

          <div class="horraires">
         <p class="jour">Jeudi</p>
         <p class="heures">11:30-13:30 / 18:00-21:00</p>
     </div>

    </div>

        <div class="paire-h">
          <div class="horraires">
         <p class="jour">Vendredi</p>
         <p class="heures">11:30-13:30 / 18:00-21:00</p>
     </div>
          <div class="horraires">
         <p class="jour">Samedi</p>
         <p class="heures">11:30-13:30 / 18:00-21:00</p>
     </div>
     </div>
     
          <div class="horraires">
         <p class="jour">Dimanche</p>
         <p class="heures"><span class="fermé">Fermé</span> / 18:00-21:00</p>
     </div>
  </div>

  <Footer></Footer>
</main>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
export default {
  components : {
      Header,
      Footer
  }
}
</script>

<style>

</style>