<template>
   <main class="carte-main">
       <Header></Header>
        <h1 class="titre-carte">Nos spécialités</h1>
    <div class="spécialités-container">
        <div class="spé">
            <p class="title-spé">AMÉRICAIN MAISON</p>
            <p class="price-spé">PETIT: 5.00€ / GRAND: 6.00€</p>
            <p class="descri-spé">(Pain artisanal, steak haché, crudités, gruyère, sauce)</p>
        </div>

        <div class="spé">
            <p class="title-spé">AMÉRICAIN RACLETTE</p>
            <p class="price-spé">PETIT: 5.00€ / GRAND: 6.00€</p>
            <p class="descri-spé">(Pain artisanal, steak haché, raclette, sauce,)</p>
        </div>

        <div class="spé">
            <p class="title-spé">AMÉRICAIN MAISON RACLETTE</p>
            <p class="price-spé">PETIT: 5.00€ / GRAND: 6.00€</p>
            <p class="descri-spé">(Pain artisanal, steak haché, crudités, raclette, sauce)</p>
        </div>
    </div>

    <div class="router-container spécialités">
    <router-link to="/carte" class="router">Découvrez notre carte complète !</router-link>
    </div>

    <div class="btn-carte-desktop bottom spécialité">
         <router-link to="/carte" class="router-carte">Découvrez notre carte complète ici</router-link>
     </div>

    <Footer></Footer>
   </main>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
export default {
    components: {
        Header,
        Footer,
    }
}
</script>

<style>

</style>