<template>
  <main class="carte-main">

    <!-- Header -->
     <Header></Header>
     <div class="desktop-top">

     <div class="desktop-v-one">

     <div class="container-slogan">
         <h1 class="slogan">La <span class="span">meilleur</span> friterie du monde selon nos clients <span class="span">!</span></h1>
     </div>
     <div class="container-phone">
         <h3 class="phone"><span class="span-italic">COMMANDEZ AU</span><br> <span class="span span-italic">06 67 96 15 97</span></h3>
     </div>
     <div class="btn-carte-desktop">
         <router-link to="/" class="router-carte">Découvrez notre carte !</router-link>
     </div>

     </div>

     <div class="desktop-top-left">
        <img src="../assets/img/logo.jpeg" alt="logo" class="logo-fdlf-desktop">
        <!-- <h2 class="horraire-desktop">Ouvert tout les jours, sauf le mardi et le dimanche midi</h2> -->
     </div>
     </div>

     <h2 class="create-americain">Créer <span class="span">ton</span> américain</h2>

    <div class="container-step-1">
    <div class="sub-step1">
    <div class="container-titre-step">
    <Cercle :number="1"></Cercle>

     <h4 class="step-title">Choisis la taille</h4>
    </div>
     <div class="size-container">
         <div class="size-price">
             <p class="size">Petit</p>
             <p class="size">Grand</p>
             <p class="size">Sandwich</p>
         </div>
         <div class="size-price right">
             <p class="price">4.50€</p>
             <p class="price">5.50€</p>
             <p class="price">4.00€</p>
         </div>
     </div>
    </div>

     <img src="../assets/img/arrow.png" alt="flèche indiquant l'étape suivante" class="arrow">

    <div class="sub-step1">

    <div class="container-titre-step">
     <Cercle :number="2"></Cercle>

     <h4 class="step-title" data-aos="fade">Choisis la viande</h4>
    </div>

     <div class="meat-container" data-aos="fade-up-right">
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Steak haché</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Steak burger</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Steak poulet</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Maxicanto</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Fricadelle</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Viandelle</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Jambon</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Lardons</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Chorizo</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Cervelas</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Merguez</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Saucisse de francfort</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Saucisse blanche</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Brochettes oignons</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Brochette provençale</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Brochette ardennaise</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Brochette grizzly</p>
         <p class="viande" data-aos="fade-right" data-aos-offset="0">Poulyroc</p>
     </div>
    </div>


    <img src="../assets/img/arrow.png" alt="flèche indiquant l'étape suivante" class="arrow">

    <div class="sub-step1">
     
    <div class="container-titre-step">
     <Cercle :number="3"></Cercle>

     <h4 class="step-title">Choisis la sauce</h4>
    </div>

    <div class="meat-container">
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Mayonnaise</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Ketchup</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Andalouse</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Américaine</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Sauce bicky</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Sauce curry</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Pita</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Brazil</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Cocktail</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Hannibale</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0"><img src="../assets/img/piments-rouge.png" alt="icone de sauce pimentée" class="chilli-pepper">Algérienne</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0"><img src="../assets/img/piments-rouge.png" alt="icone de sauce pimentée" class="chilli-pepper">Samourai</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Barbecue</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Poivre</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Pickels</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Tartare</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0">Moutarde</p>
         <p class="sauce" data-aos="fade-up" data-aos-offset="0"><img src="../assets/img/piments-rouge.png" alt="icone de sauce pimentée" class="chilli-pepper">Harissa</p>
     </div>

     
    </div>

    <img src="../assets/img/arrow.png" alt="flèche indiquant l'étape suivante" class="arrow">

    <div class="sub-step1">
        
    <div class="container-titre-step">
    <Cercle :number="4"></Cercle>

    <h4 class="step-title">Et pour finir... les extras !</h4>
    </div>

    <div class="meat-container" data-aos="zoom">
        <div class="extra">
            <p class="price extra-price">+ 0.50€</p>
            <p class="extra-sub">Salade</p>
            <p class="extra-sub">Tomates</p>
            <p class="extra-sub">Cornichons</p>
            <p class="extra-sub">Oignons <span class="span-mini">(crus,cuits,séchés)</span></p>
        </div>

        <div class="extra">
            <p class="price extra-price">+ 1.00€</p>
            <p class="extra-sub">Cheddar</p>
            <p class="extra-sub">Raclette</p>
            <p class="extra-sub">Rocquefort</p>
            <p class="extra-sub">Gruyère</p>
        </div>

         <div class="extra">
            <p class="price extra-price">+ 1.00€</p>
            <p class="extra-sub">Chorizo</p>
            <p class="extra-sub">Lardons</p>
            <p class="extra-sub">Jambon</p>
        </div>
    </div>

    </div>

     </div>

    <div class="container-slogan-end">
    <h1 class="slogan-end" data-aos="zoom-in" data-aos-offset="0">Et il ne nous reste plus qu'à vous souhaiter un bon <span class="span">appétit</span> !</h1>
    </div>

    <div class="router-container">
    <router-link to="/carte" class="router">Découvrez notre carte complète !</router-link>
    </div>
    <div class="btn-carte-desktop bottom">
         <router-link to="/carte" class="router-carte">Découvrez notre carte complète ici</router-link>
     </div>

    <Footer></Footer>

  </main>
</template>

<script>
import AOS from "aos";
import Header from '../components/Header.vue'
import Cercle from '../components/Cercle.vue'
import Footer from '../components/Footer.vue'

export default {
    components : {
        Header,
        Cercle,
        Footer,
    },

     created() {
    AOS.init();
  },
}
</script>

<style>

</style>