<template>
      <div class="footer">
       <p class="adresse-f">Pl. de la Fontaine, 08110 Carignan</p>
       <p class="phone-f">06 67 96 15 97</p>
       <p>Suivez-nous sur nos réseaux sociaux !</p>
       <a href="https://www.instagram.com/friterie_de_la_fontaine/?hl=fr" target="_blank" class="social-router">
       <img src="../assets/img/instagram.png" alt="instagram" class="icons-social">
       </a>
       <a href="https://www.facebook.com/Friteriedelafontaine/" target="_blank" class="social-router">
       <img src="../assets/img/facebook.png" alt="facebook" class="icons-social">
       </a>
       <p class="pub">Site réalisé par Oscar PRIVITERA</p>
       <p class="copyright">Copyright 2021 Friterie de la Fontaine. Tous droits réservés</p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>