<template>
    <div id="transition-page-test">
    <div id="transition-page-contenu">
    <img src="../assets/img/logo.jpeg" alt="logo" class="logo-fdlf transition">
    </div>
  </div>
</template>

<script>
export default {
data() {
    return {
      loading: 0,
      succes: false
    }
  },

    mounted() {
      this.$store.state.appearMenu = false;
      const divTransi = document.querySelector("#transition-page-test");
      divTransi.style.display = "flex";
      divTransi.style.opacity = "1";

    setTimeout(() => {
          divTransi.style.opacity = "0"
        }, 800);
        setTimeout(() => {
          divTransi.style.display = "none"
        }, 1200)
  },

  methods: {
  }

}
</script>

<style>

</style>