<template>
       <div class="circle-step">
         <p class="step-number"> {{ number }}</p>
     </div>
</template>

<script>
export default {
   props : {
       number : Number
   }
}
</script>

<style>

</style>