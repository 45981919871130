import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Accueil.vue'
import Carte from '../views/Carte.vue'
import Localisation from '../views/Localisation.vue'
import Spécialités from '../views/Spécialités.vue'
import Horaires from '../views/Horaires.vue'

const routes = [
  {
    path: '/',
    redirect: '/accueil'
  },
  {
    path: '/accueil',
    name: 'accueil',
    component: Accueil
  },
  {
    path: '/carte',
    name: 'carte',
    component: Carte
  },
  {
    path: '/localisation',
    name: 'localisation',
    component: Localisation
  },
  {
    path: '/specialites',
    name: 'specialites',
    component: Spécialités
  },
  {
    path: '/horaires',
    name: 'horaires',
    component: Horaires
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
