<template>
<main class="carte-main">
    <Header></Header>
    <div class="localisation-container">
    <h1 class="titre-carte">Nous sommes ici !</h1>
    </div>
  <div style="width: 100%; height: 70vh">
    <iframe
      width="100%"
      height="100%"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Friterie%20De%20La%20Fontaine,%20Place%20de%20la%20Fontaine,%20Carignan+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ><a href="https://www.gps.ie/car-satnav-gps/">best sat navs</a></iframe
    >
  </div>
  <Footer></Footer>
</main>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
export default {
    components : {
        Header,
        Footer,
    }
};
</script>

<style>
</style>